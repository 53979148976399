// import "../new/addnew.scss";
import "./addreg.scss";
import { TextField, Button, FormControl } from "@material-ui/core";
import { useState, useEffect } from "react";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import Newside from "../../components/sidebar/Newside";

const Addregistration = () => {
  const { notification, setNotification, user, setUser } = useStateContext();
  const [formData, setFormData] = useState({
    drivers_licence: "",
    name: "",
    surname: "",
    date_of_birth: "",
    insurance_policy_no: "",
    vehicle_registration: "",
    mark: "",
    chasis_number: "",
    destination: "",
    purpose_of_trip: "",
    number_of_passengers: "",
    number_of_articles: "",
    country_of_registry: "",
    date_of_arrival: "",
    date_of_departure: "",
    expiry_date: "",
  });

  const [driver, setDriver] = useState();

  useEffect(() => {
    axiosClient.get("/user").then(({ data }) => {
      setUser(data);
    });
  }, []);

  const fetchDriver = (e) => {
    axiosClient
      // .get(`/driver/check/${formData.drivers_licence}`)
      .get(`/driver/check/${e.target.value}`)
      .then((response) => {
        // show success message
        setDriver(response.data[0]);
        // Object.assign(formData, response.data[0]);
        // console.log(formData);
        if (response.data[0]) {
          setFormData({
            ...formData,
            drivers_licence:response.data[0].drivers_licence,
            name: response.data[0].name,
            surname: response.data[0].surname,
            insurance_policy_no: response.data[0].insurance_policy_no,
            date_of_birth: response.data[0].date_of_birth,
          });

          // Object.assign(formData, response.data[0]);
        } else {
          // setFormData({
          //   name: "",
          //   surname: "",
          //   date_of_birth: "",
          //   insurance_policy_no: "",
          // });
          setNotification("Driver does not exist");
        }
      })
      .catch((error) => {
        // show error message
        console.log(error);
      });

    // try {
    //   const response = await axiosClient.post("/driver/check", formData);
    //   setDriver(response.data);
    //   // console.log(response.data);
    // } catch (error) {
    //   console.error(error);
    // }
    // Object.assign(formData,driver[0]);
  };

  const fetchVehicle = (e) => {
    axiosClient
      // .get(`/vehicle/check/${formData.vehicle_registration}`)
      .get(`/vehicle/check/${e.target.value}`)
      .then((response) => {
        // show success message
        setDriver(response.data[0]);
        // Object.assign(formData, response.data[0]);
        // console.log(formData);
        if (response.data[0]) {
          setFormData({
            ...formData,
            vehicle_registration:response.data[0].vehicle_registration,
            mark: response.data[0].mark,
            chasis_number: response.data[0].chasis_number,
          });
        } else {
          // setFormData({
          //   mark: "",
          //   chasis_number: "",
          // });
          setNotification("Vehicle does not exist");
        }
      })
      .catch((error) => {
        // show error message
        console.log(error);
      });

    // try {
    //   const response = await axiosClient.post("/driver/check", formData);
    //   setDriver(response.data);
    //   // console.log(response.data);
    // } catch (error) {
    //   console.error(error);
    // }
    // Object.assign(formData,driver[0]);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    console.log(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosClient.post("/test", formData);
      setNotification("Successfully registered!");
    } catch (error) {
      setNotification("Error registering, please try again.");
      console.log(error);
    }
    console.log(formData);
  };

  const [selectedLocation, setSelectedLocation] = useState("");
  const handleLocationChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setSelectedLocation(e.target.value);

    console.log(e.target.value);
  };

  const [selectedPurpose, setSelectedPurpose] = useState("");
  const handlePurposeChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setSelectedPurpose(e.target.value);

    console.log(e.target.value);
  };

  const [selectedCountReg, setSelectedCountReg] = useState("");
  const handleCountRegChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    setSelectedCountReg(e.target.value);

    console.log(e.target.value);
  };

  const handleDriverChange = (e)=>{
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    fetchDriver(e);

  }

  //testing vehicle change
  const handleVehicleChange = (e)=>{
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    fetchVehicle(e);

  }

  return (
    <div className="addregistration">
      {/* <Sidebar /> */}
      <Newside/>
      <div className="registrationContainer">
        {/* <Navbar /> */}
        <div>
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              name="drivers_licence"
              label="Drivers licence"
              value={formData.drivers_licence}
              onChange={handleDriverChange}
              required
            />
            {/* <Button onClick={fetchDriver} variant="contained" color="primary">
              Search
            </Button> */}
            <TextField
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              name="surname"
              label="Surname"
              value={formData.surname}
              onChange={handleChange}
              required
            />
            <TextField
              name="date_of_birth"
              helperText="Date of birth"
              value={formData.date_of_birth}
              onChange={handleChange}
              type="date"
              required
            />

            <TextField
              name="insurance_policy_no"
              label="Insurance policy number"
              value={formData.insurance_policy_no}
              onChange={handleChange}
              required
            />

            <TextField
              name="vehicle_registration"
              label="Vehicle registration"
              value={formData.vehicle_registration}
              onChange={handleVehicleChange}
              required
            />
            {/* <Button onClick={fetchVehicle} variant="contained" color="primary">
              Search
            </Button> */}
            <TextField
              name="mark"
              label="Mark"
              value={formData.mark}
              onChange={handleChange}
              required
            />
            <TextField
              name="chasis_number"
              label="Chasis number"
              value={formData.chasis_number}
              onChange={handleChange}
              required
            />
            {/* <FormControl variant="outlined">
              <select
                name="destination"
                value={formData.destination}
                onChange={handleChange}
                required
              >
                <option value="">-- Select Destination --</option>
                <option value="The Gambia">The Gambia</option>
                <option value="Senegal">Senegal</option>
              </select>
            </FormControl> */}

            <FormControl variant="outlined">
              <FormLabel>Select Destination</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="destination"
                      onChange={handleLocationChange}
                      value="Senegal"
                      checked={selectedLocation === "Senegal"}
                    />
                  }
                  label="Senegal"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="destination"
                      onChange={handleLocationChange}
                      value="The Gambia"
                      checked={selectedLocation === "The Gambia"}
                    />
                  }
                  label="The Gambia"
                />
              </FormGroup>
            </FormControl>

            <FormControl>
              <FormLabel>Purpose of Trip</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="purpose_of_trip"
                      onChange={handlePurposeChange}
                      value="Business"
                      checked={selectedPurpose === "Business"}
                    />
                  }
                  label="Business"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="purpose_of_trip"
                      onChange={handlePurposeChange}
                      value="Visit"
                      checked={selectedPurpose === "Visit"}
                    />
                  }
                  label="Visit"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="purpose_of_trip"
                      onChange={handlePurposeChange}
                      value="Stay"
                      checked={selectedPurpose === "Stay"}
                    />
                  }
                  label="Stay"
                />
              </FormGroup>
            </FormControl>

            {/* <FormControl variant="outlined">
              <select
                name="purpose_of_trip"
                value={formData.purpose_of_trip}
                onChange={handleChange}
                required
              >
                <option value="">-- Purpose of Trip --</option>
                <option value="Business">Business</option>
                <option value="Visit">Visit</option>
                <option value="Stay">Stay</option>
              </select>
            </FormControl> */}

            <TextField
              name="number_of_passengers"
              label="Number of passengers"
              value={formData.number_of_passengers}
              onChange={handleChange}
              required
            />
            <TextField
              name="number_of_articles"
              label="Number of articles"
              value={formData.number_of_articles}
              onChange={handleChange}
              required
            />

            {/* <TextField
              name="country_of_registry"
              label="Country of registry"
              value={formData.country_of_registry}
              onChange={handleChange}
              required
            /> */}

            <FormControl variant="outlined">
              <FormLabel>Country of Registry</FormLabel>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="country_of_registry"
                      onChange={handleCountRegChange}
                      value="The Gambia"
                      checked={selectedCountReg === "The Gambia"}
                    />
                  }
                  label="The Gambia"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="country_of_registry"
                      onChange={handleCountRegChange}
                      value="Other"
                      checked={selectedCountReg === "Other"}
                    />
                  }
                  label="Other"
                />
              </FormGroup>
            </FormControl>

            <TextField
              name="date_of_arrival"
              helperText="Date of arrival"
              value={formData.date_of_arrival}
              onChange={handleChange}
              type="date"
              required
            />
            <TextField
              name="date_of_departure"
              helperText="Date of departure"
              value={formData.date_of_departure}
              onChange={handleChange}
              type="date"
              required
            />
            <TextField
              name="expiry_date"
              helperText="Expiry date"
              value={formData.expiry_date}
              onChange={handleChange}
              type="date"
              required
            />
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
          {notification && (
            <div
              className={`notification ${
                notification === "Successfully registered!"
                  ? "success"
                  : "error"
              }`}
            >
              {notification}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Addregistration;
