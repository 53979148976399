import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";

import { useEffect, useState } from "react";
import { useStateContext } from "../../context/ContextProvider";
import axiosClient from "../../axios-client";
import { Button } from "@mui/material";
import ResponsiveDialog from "./ResponsiveDialog";
import Deletedialog from "./Deletedialog";
import Regdialog from "../../pages/registration/Regdialog";

const Datatable = () => {
  // const [data, setData] = useState(userRows);

  // const handleDelete = (id) => {
  //   setData(data.filter((item) => item.id !== id));
  // };

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { notification, setNotification, user, setUser } = useStateContext();
  // useEffect(() => {
  //   getUsers();
  // }, []);

  // const onDeleteClick = (user) => {
  //   if (!window.confirm("Are you sure you want to delete this user?")) {
  //     return;
  //   }
  //   axiosClient.delete(`/users/${user.id}`).then(() => {
  //     setNotification("User was successfully deleted");
  //     getUsers();
  //   });
  // };

  // const getUsers = () => {
  //   setLoading(true);
  //   axiosClient
  //     .get("/users")
  //     .then(({ data }) => {
  //       setLoading(false);
  //       setUsers(data.data);
  //       // console.log(data)
  //     })
  //     .catch(() => {
  //       setLoading(false);
  //     });
  // };
  // console.log(users);

  const [rows, setRows] = useState([]);
 
  var userData =[];

  useEffect(() => {
    axiosClient.get("/user").then(({ data }) => {
      setUser(data);
      userData= data;
      console.log(userData.branch);
      if (userData.branch === "GRA") {
          fetchgraq();
        
      }else if(userData.branch === "GPF"){
          fetchgpfq();
          
      }
    });

    const fetchgpfq = async () => {
      try {
        const response = await axiosClient.get("/test/seegpfq");
        setRows(response.data);
        console.log(response.data);

      } catch (error) {
        console.error(error);
      }
    };
  
    const fetchgraq = async () => {
      try {
        const response = await axiosClient.get("/test/seegraq");
        setRows(response.data);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    

    // if (sheesh.branch === "GRA") {
    //   console.log('gra');
    //     // fetchgraq();
    //     const fetchgraq = async () => {
    //       try {
    //         const response = await axiosClient.get("/test/seegraq");
    //         setRows(response.data);
    //         console.log(response.data);
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };
    // }else if(sheesh.branch === "GPF"){
    //   console.log('gpf');
    //     // fetchgpfq();
    //     const fetchgpfq = async () => {
    //       try {
    //         const response = await axiosClient.get("/test/seegpfq");
    //         setRows(response.data);
    //         console.log(response.data);
    
    //       } catch (error) {
    //         console.error(error);
    //       }
    //     };
    // }
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 150,
      renderCell: (params) => {
        return (
          <div className="cellAction">
            {/* <Link to="/" className="link"> */}

            <Deletedialog id={params.row.id} />
            {/* </Link> */}
            {/* <div
              className="deleteButton"
              onClick={() => handleDelete(params.row.id)}
            >
              Delete
            </div> */}
          </div>
        );
      },
    },
  ];

  const approveRegistration = (rows) => {
    axiosClient
      .patch(`/test/q/${rows}`)
      .then((response) => {
        // show success message
        console.log("Registration approved");
      })
      .catch((error) => {
        // show error message
        console.log("Error approving registration");
      });
  };

   const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "surname", headerName: "Surname", width: 150 },
    // { field: "date_of_birth", headerName: "Date of Birth", width: 150 },
    { field: "drivers_licence", headerName: "Driver's licence", width: 150 },
    { field: "mark", headerName: "Mark", width: 150 },
    {
      field: "vehicle_registration",
      headerName: "License Plate #",
      width: 150,
    },
    { field: "chasis_number", headerName: "Chasis number", width: 150 },
    // { field: "destination", headerName: "Destination", width: 150 },
    // { field: "purpose_of_trip", headerName: "Purpose of trip", width: 150 },
    // {
    //   field: "number_of_passengers",
    //   headerName: "Number of passengers",
    //   width: 150,
    // },
    // {
    //   field: "number_of_articles",
    //   headerName: "Number of articles",
    //   width: 150,
    // },
    {
      field: "country_of_registry",
      headerName: "Country of registry",
      width: 150,
    },
    // { field: "date_of_departure", headerName: "Date of departure", width: 150 },
    // { field: "date_of_arrival", headerName: "Date of arrival", width: 150 },
    { field: "expiry_date", headerName: "Expiry date", width: 150 },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   width: 150,
    //   valueGetter: (params) =>
    //     params.row.status === 0 ? "Pending" : "Approved",
    //   cellClassName: (params) =>
    //     params.value === "Pending" ? classes.pendingCell : classes.approvedCell,
    // },
    {
      field: "details",
      headerName: "More Details",
      width: 150,
      renderCell: (params) => {
        return <Regdialog data={params.row} />;
      },
    },
  ];

  // const actionColumn = [
  //   {
  //     field: "action",
  //     headerName: "Action",
  //     width: 200,
  //     cellRenderer: (params) => {
  //       return (
  //         <div className="cellAction">
  //           <div
  //             className="viewButton"
  //             onClick={() => approveRegistration(params.row.id)}
  //           >
  //             Approve
  //           </div>
  //         </div>
  //       );
  //     },
  //   },
  // ];

  return (
    <div className="datatable">
      <div className="datatableTitle"></div>
      <DataGrid
        className="datagrid"
        rows={rows}
        columns={columns.concat(actionColumn)}
        pageSize={9}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
};

export default Datatable;
