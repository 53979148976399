import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import Single from "./pages/single/Single";
import New from "./pages/new/New";
import Queue from "./pages/queue/Queue";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  Router,
} from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import Traveller from "./pages/traveller/Traveller";
import { useStateContext } from "./context/ContextProvider";
import PendingQ from "./components/datatable/PendingQ";
import Addregistration from "./pages/registration/Addregistration";
import Queuelayot from "./pages/list/Queuelayot";
import Profile from "./pages/profile/Profile";
import Map from "./pages/home/Map";
import SuperAdmin from "./pages/superAdmin/SuperAdmin";
import Viewregistration from "./pages/registration/Viewregistration";
import TestMap from "./pages/home/TestMap";
import Newside from "./components/sidebar/Newside";
import FullscreenBox from "./pages/tests/FullscreenBox";
import SuperAdminData from "./pages/superAdmin/SuperAdminData";

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const { token } = useStateContext();

  return (
    <div className={darkMode ? "app dark" : "app"}>
      {!token ? (
        
        <Login />
      ) : (
        <BrowserRouter>
          <Routes>
           
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="users">
                <Route index element={<Traveller />} />
                {/* <Route path=":userId" element={<Single />} /> */}
                <Route
                  path="new"
                  element={<New inputs={userInputs} title="Add New User" />}
                />
              </Route>
              <Route path="q">
                <Route index element={<List />} />
                {/* <Route path=":productId" element={<Single />} /> */}
                {/* <Route
                  path="new"
                  element={
                    <New inputs={productInputs} title="Add New Product" />
                  }
                /> */}
              </Route>
              <Route path="registration">
                <Route index element={<Addregistration />} />
              </Route>

              <Route path="pendingq">
                <Route index element={<Queuelayot />} />
              </Route>
              <Route path="profile">
                <Route index element={<Profile />} />
              </Route>
              <Route path="allregs">
                <Route index element={<Viewregistration />} />
              </Route>
              <Route path="super">
                <Route index element={<SuperAdmin />} />
              </Route>
              <Route path="map">
                <Route index element={<TestMap />} />
              </Route>
              <Route path="sidetest">
                <Route index element={<SuperAdminData />} />
              </Route>
            </Route>
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
};

export default App;

  // return (
  //   <div className={darkMode ? "app dark" : "app"}>
  //     <BrowserRouter>
  //       <Routes>
  //         <Route path="/login" element={<Login />} />

  //         <Route path="/">
  //           <Route index element={<Home />} />
  //           <Route path="users">
  //             <Route index element={<Traveller />} />
  //             <Route path=":userId" element={<Single />} />
  //             <Route
  //               path="new"
  //               element={<New inputs={userInputs} title="Add New User" />}
  //             />
  //           </Route>
  //           <Route path="q">
  //             <Route index element={<List />} />
  //             <Route path=":productId" element={<Single />} />
  //             <Route
  //               path="new"
  //               element={<New inputs={productInputs} title="Add New Product" />}
  //             />
  //           </Route>
  //           <Route path="registration">
  //             <Route index element={<Addregistration />} />
  //           </Route>

  //           <Route path="pendingq">
  //             <Route index element={<Queuelayot />} />
  //           </Route>
  //         </Route>
  //       </Routes>
  //     </BrowserRouter>
  //   </div>
  
