import "./addnew.scss";
import { TextField, Button } from "@material-ui/core";
import { useState, useEffect } from "react";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { FormControl } from "@mui/material";
import Newside from "../../components/sidebar/Newside";

const New = () => {
  const { notification, setNotification, user, setUser } = useStateContext();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    post: "",
    password: "",
    password_confirmation: "",
  });
  const navigate = useNavigate();

  useEffect(() => {
    axiosClient.get("/user").then(({ data }) => {
      setUser(data);
    });
  }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (user.user_type === "gpfAdmin") {
      try {
        await axiosClient.post("/newGpf", formData);
        setNotification("Successfully registered!");
      } catch (error) {
        setNotification(error.response.data.message);
        console.log(error.response.data.message);
      }
    } else if (user.user_type === "graAdmin") {
      try {
        await axiosClient.post("/newGra", formData);
        setNotification("Successfully registered!");
      } catch (error) {
        setNotification("Error registering, please try again.");
      }
    } else {
      console.log("you can not register new user");
    }
  };

  return (
    <div className="new">
      {/* <Sidebar/> */}
      <Newside />
      <div className="newContainer">
        {/* <Navbar/> */}
        <div>
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              name="name"
              label="Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <TextField
              name="email"
              label="Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <FormControl variant="outlined">
              <select
                name="post"
                value={formData.post}
                onChange={handleChange}
                required
              >
                <option value="">-- Select Border Post --</option>
                <option value="Amdalai">Amdalai</option>
                <option value="Giboro">Giboro</option>
                <option value="Bwiam">Bwiam</option>
                <option value="Darsilami">Darsilami</option>
                <option value="Kiona">Kiona</option>
                <option value="Farafenni">Farafenni</option>
                <option value="Yelli Tenda">Yelli Tenda</option>
              </select>
            </FormControl>
            <TextField
              name="password"
              label="Password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <TextField
              name="password_confirmation"
              label="Password Confirmation"
              type="password"
              value={formData.password_confirmation}
              onChange={handleChange}
              required
            />
            <Button type="submit" variant="contained" color="primary">
              Add
            </Button>
          </form>
          {notification && (
            <div
              className={`notification ${
                notification === "Successfully registered!"
                  ? "success"
                  : "error"
              }`}
            >
              {notification}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default New;
