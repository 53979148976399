import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import axiosClient from "../../axios-client";

const borders = [
  {
    name: "Amdalai",
    coords: [
      [13.652300863586676, -16.427315099095328],
      [13.4484, -16.4413],
    ],
  },
  {
    name: "Giboro",
    coords: [
      [13.173454877672922, -16.576000240443083],
      [13.4766, -16.7832],
    ],
  },
  {
    name: "Bwiam",
    coords: [
      [13.3337, -15.4707],
      [13.4072, -15.5932],
    ],
  },
  {
    name: "Kiona",
    coords: [
      [13.3523, -14.7603],
      [13.4573, -14.8463],
    ],
  },
  {
    
    name: "Darsilami",
    coords: [
      [13.172932400511053, -16.656589733578457],
      [13.4246, -15.3016],
    ],
  },
  {
    name: "Farafenni",
    coords: [
      [13.5681, -15.6232],
      [13.6167, -15.6959],
    ],
  },
  {
    name: "Yelli Tenda",
    coords: [
      [13.4281, -15.8478],
      [13.4861, -15.9148],
    ],
  },
];

function TestMap() {
  const [position, setPosition] = useState([13.47, -15.57]);

  const [inBar, setInBar] = useState([]);
  const [outBar, setOutBar] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get("/incoming");
        setInBar(response.data.in);
        setOutBar(response.data.out);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  const getBorderCount = (borderName) => {
    const inCount =
      inBar.find((border) => border.border === borderName)?.border_count || 0;
    const outCount =
      outBar.find((border) => border.border === borderName)?.border_count || 0;
    return {inCount,outCount};
  };

  const addMarkers = () => {
    return borders.map((border,index) => {
      const borderCount = getBorderCount(border.name);

      return (
        <Marker key={index} position={border.coords[0]}>
          <Popup>
            <div>
              <h3>{border.name}</h3>
              <p>Incoming: {borderCount.inCount}</p>
              <p>Outgoing: {borderCount.outCount}</p>

            </div>
          </Popup>
        </Marker>
      );
    });
  };

  return (
    <MapContainer
      center={position}
      zoom={8.1}
      style={{ height: "400px", width: "100%", zIndex: 0 }}
    >
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {addMarkers()}
    </MapContainer>
  );
}

export default TestMap;
