import "./traveller.scss";
import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import axiosClient from "../../axios-client";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Newside from "../../components/sidebar/Newside";
import UserDialog from "./UserDialog";

const columns = [
  { field: "name", headerName: "Name", width: 200 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "post", headerName: "Post", width: 300 },
];

const actionColumn = [
  {
    field: "action",
    headerName: "Action",
    width: 200,
    renderCell: (params) => {
      return <UserDialog id={params.row.id} />;
    },
  },
];

const Traveller = () => {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get("/users");
        setRows(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="traveller">
      {/* <Sidebar /> */}
      {/* all new sidebar has margin top of 50px at scss */}
      <Newside />

      <div className="travellerContainer">
        {/* <Navbar /> */}

        <div className="table">
          <div className="tableTitle">
            Created Users
            <Link to="/users/new" className="link">
              Add New
            </Link>
          </div>

          <DataGrid
            className="datagrid"
            rows={rows}
            columns={columns.concat(actionColumn)}
            pageSize={5}
            rowsPerPageOptions={[5]}
            checkboxSelection
          />
        </div>
      </div>
    </div>
  );
};
export default Traveller;
