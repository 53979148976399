import "./profile.scss";
import React, { useState } from "react";
import { TextField, Button } from "@material-ui/core";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import Newside from "../../components/sidebar/Newside";

const Profile = () => {
  const [current_password, setPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [new_password_confirmation, setConfirmPassword] = useState("");
  const { notification, setNotification} = useStateContext();


  const handleSubmit = (event) => {
    event.preventDefault();

    // Perform password change logic here
   
         axiosClient.put("/password", {current_password,new_password,new_password_confirmation}).then((response) => {
            // show success message
            setNotification("Password changed successfully!");

            console.log(response.data);
          })
          .catch((error) => {
            // show error message
            setNotification(error.response.data);
            console.log(error.response.data);
          });
    
  };

  return (
    <div className="profile">
      {/* <Sidebar /> */}
      <Newside/>

      <div className="profileContainer">
        {/* <Navbar /> */}
        <div>
          <div className="profileTitle">
            Change Password
          </div>
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              id="current_password"
              label="Current Password"
              type="password"
              value={current_password}
              onChange={(event) => setPassword(event.target.value)}
              margin="normal"
              required
            />
            <TextField
              id="new_password"
              label="New Password"
              type="password"
              value={new_password}
              onChange={(event) => setNewPassword(event.target.value)}
              margin="normal"
              required
            />
            <TextField
              id="new_password_confirmation"
              label="Confirm Password"
              type="password"
              value={new_password_confirmation}
              onChange={(event) => setConfirmPassword(event.target.value)}
              margin="normal"
              required
            />
            <Button type="submit" variant="contained" color="primary">
              Change Password
            </Button>
          </form>
          {notification && (
            <div
              className={`notification ${
                notification === "Password changed successfully!"
                  ? "success"
                  : "error"
              }`}
            >
              {notification}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
