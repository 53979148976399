import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import Regdialog from "./Regdialog";

const useStyles = makeStyles((theme) => ({
  pendingCell: {
    color: "orange",
  },
  approvedCell: {
    color: "green",
  },
}));

function Viewregistration() {
  function click(e) {
    console.log(e);
  }
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  useEffect(() => {
    return axiosClient
      .get("/test")
      .then((result) => {
        setRows(result.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const columns = [
    { field: "name", headerName: "Name", width: 150 },
    { field: "surname", headerName: "Surname", width: 150 },
    // { field: "date_of_birth", headerName: "Date of Birth", width: 150 },
    { field: "drivers_licence", headerName: "Driver's licence", width: 150 },
    { field: "mark", headerName: "Mark", width: 150 },
    {
      field: "vehicle_registration",
      headerName: "License Plate #",
      width: 150,
    },
    { field: "chasis_number", headerName: "Chasis number", width: 150 },
    // { field: "destination", headerName: "Destination", width: 150 },
    // { field: "purpose_of_trip", headerName: "Purpose of trip", width: 150 },
    // {
    //   field: "number_of_passengers",
    //   headerName: "Number of passengers",
    //   width: 150,
    // },
    // {
    //   field: "number_of_articles",
    //   headerName: "Number of articles",
    //   width: 150,
    // },
    {
      field: "country_of_registry",
      headerName: "Country of registry",
      width: 150,
    },
    // { field: "date_of_departure", headerName: "Date of departure", width: 150 },
    // { field: "date_of_arrival", headerName: "Date of arrival", width: 150 },
    { field: "expiry_date", headerName: "Expiry date", width: 150 },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      valueGetter: (params) =>
        params.row.status === 0 ? "Pending" : "Approved",
      cellClassName: (params) =>
        params.value === "Pending" ? classes.pendingCell : classes.approvedCell,
    },
    {
      field: "details",
      headerName: "More details",
      width: 150,
      renderCell: (params) => {
        return <Regdialog data={params.row} />;
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">All Registrations </div>
      <Link to="/" style={{ textDecoration: "none" }}>
        <div>
          <Button>Back</Button>
        </div>
      </Link>
      <DataGrid
        className="datagrid"
        rows={rows}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[9]}
        checkboxSelection
      />
    </div>
  );
}

export default Viewregistration;
