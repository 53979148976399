import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";



export default function ResponsiveDialog(prop) {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const approveRegistration = (id) => {
    axiosClient
      .patch(`/test/q/${id}`)
      .then((response) => {
        // show success message
        console.log(response);
        handleClose();
        navigate('/');
      })
      .catch((error) => {
        // show error message
        console.log(error);
      });

  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Approve
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Approve user"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>Do you want to approve?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            No
          </Button>
          <Button onClick={() => approveRegistration(prop.id)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
