import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import {
  ArgumentAxis,
  ValueAxis,
  Chart,
  BarSeries,
  Title,

} from "@devexpress/dx-react-chart-material-ui";
import axiosClient from "../../axios-client";

const Barchart = () => {
  const [inBar, setInBar] = useState([]);
  const [outBar,setOutBar] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosClient.get("/incoming");
        setInBar(response.data.in);
        setOutBar(response.data.out);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  // Sample data
  const data = [
    { argument: "Monday", value: 30 },
    { argument: "Tuesday", value: 20 },
    { argument: "Wednesday", value: 10 },
    { argument: "Thursday", value: 50 },
    { argument: "Friday", value: 60 },
  ];
  return (
    <div  style={{ width: "100%",display:"flex",gap:"1rem" }}>
         <Paper className="barchart" style={{width: "50%", color:"gray"}} >
      <Chart  data={inBar}>
        <ArgumentAxis  />
        <ValueAxis stroke="#8884d8"  />

        <BarSeries valueField="border_count" argumentField="border" />
        <Title text="Incomming Traffic"/>
      </Chart>
    </Paper>
    <Paper className="barchart" style={{width: "50%"}}>
    <Chart  data={outBar} fill="#8884d8">
      <ArgumentAxis  />
      <ValueAxis   />

      <BarSeries valueField="border_count" argumentField="border" />
      <Title text="Outgoing Traffic"/>

    </Chart>
  </Paper>
    </div>
    
  );
};

export default Barchart;
