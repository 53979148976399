import "./super.scss";
import { TextField, Button } from "@material-ui/core";
import { useState, useEffect } from "react";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { FormControl } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SuperAdminData from "./SuperAdminData";
import Newside from "../../components/sidebar/Newside";

const SuperAdmin = () => {
  const { notification, setNotification, user, setUser } = useStateContext();
  const [formData, setFormData] = useState({
    post: "",
    user_type: "",
  });

  const navigate = useNavigate();

  useEffect(() => {
    axiosClient.get("/user").then(({ data }) => {
      setUser(data);
    });
  }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    axiosClient
      .put("/super", formData)
      .then(() => {
        setNotification("Successfully changed!");
        navigate('/');
        window.location.reload();
      })
      .catch((error) => {
        // show error message
        setNotification(error.response.data.message);
      });
  };

  return (
    <div className="new">
      {/* <Sidebar /> */}
      <Newside />
      <div className="newContainer">
        {/* <Navbar /> */}
        <div>
          <form className="form" onSubmit={handleSubmit}>
            <FormControl variant="outlined">
              <select
                name="post"
                value={formData.post}
                onChange={handleChange}
                required
              >
                <option value="">-- Select Border Post --</option>
                <option value="Amdalai">Amdalai</option>
                <option value="Giboro">Giboro</option>
                <option value="Bwiam">Bwiam</option>
                <option value="Darsilami">Darsilami</option>
                <option value="Kiona">Kiona</option>
                <option value="Farafenni">Farafenni</option>
                <option value="Yelli Tenda">Yelli Tenda</option>
              </select>
            </FormControl>

            <FormControl>
              <select
                name="user_type"
                value={formData.user_type}
                onChange={handleChange}
                required
              >
                <option value="">-- Select User Type --</option>
                <option value="graAdmin">GRA Admin</option>
                <option value="gpfAdmin">GPF Admin</option>
              </select>
            </FormControl>

            <Button type="submit" variant="contained" color="primary">
              Change 
            </Button>
          </form>
          <SuperAdminData/>
          {notification && (
            <div
              className={`notification ${
                notification === "Successfully changed!"
                  ? "success"
                  : "error"
              }`}
            >
              {notification}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SuperAdmin;
