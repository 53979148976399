import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart from "../../components/chart/Chart";
import Piechart from "./Piechart";
import Barchart from "./Barchart";
import TestMap from "./TestMap";
import Newside from "../../components/sidebar/Newside";

const Home = () => {
  return (
    <div className="home">
      {/* <Sidebar /> */}
      <Newside/>
      <div className="homeContainer ">
        {/* <Navbar /> */}
        <div className="widgets">
          <Widget  type="earning" />
          <Widget type="registration" />
        </div>
        <div className="listContainer">
          <div className="listTitle">Border visits</div>
          <div className="listItem">
          <Barchart />
          <Piechart/>
          </div>
          {/* <Table /> */}
          <div className="listTitle">Border Map</div>
          <TestMap />
          {/* <Map/> */}
        </div>
        
        <div className="charts">
          <Featured />
          <Chart title="Last 6 Days (Revenue)" aspect={2 / 1} />
        </div>
      </div>
    </div>
  );
};

export default Home;
