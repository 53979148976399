

import React from "react";
import ReactDOM from "react-dom";
import { RouterProvider } from "react-router-dom";
import App from "./App";
import { ContextProvider } from "./context/ContextProvider";
import { DarkModeContextProvider } from "./context/darkModeContext";
import router from "./router";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';



// if ('serviceWorker' in navigator) {
//   window.addEventListener('load', function() {
//     navigator.serviceWorker.register('/service-worker.js');
//   });
// }
ReactDOM.render(
  <React.StrictMode>
    <ContextProvider>
    <DarkModeContextProvider>
      
    {/* <RouterProvider router={router} /> */}
      
      <App/>
      
    </DarkModeContextProvider>
    </ContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

//service worker
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();