import "./list.scss"
import Sidebar from "../../components/sidebar/Sidebar"
import Navbar from "../../components/navbar/Navbar"
import PendingQ from "../../components/datatable/PendingQ"
import Newside from "../../components/sidebar/Newside"

const Queuelayot = () => {
  return (
    <div className="list">
      {/* <Sidebar/> */}
      <Newside/>
      <div className="listContainer">
        {/* <Navbar/> */}
        <PendingQ/>
      </div>
    </div>
  )
}

export default Queuelayot