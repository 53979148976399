import React from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import "../../components/featured/featured.scss"

function SuperAdminData() {

    const [graAmount, setGraAmount] = useState(0);
    const [graLastweek, setGraLastweek] = useState(0);
    const [graLastMonth, setGraLastMonth] = useState(0);
  
    const [gpfAmount, setGpfAmount] = useState(0);
    const [gpfLastweek, setGpfLastweek] = useState(0);
    const [gpfLastMonth, setGpfLastMonth] = useState(0);

    useEffect(() => {
      axiosClient.get("/superinfo").then(({ data }) => {
        setGraAmount(data.graTotal);
        setGraLastweek(data.graLastWeek);
        setGraLastMonth(data.graLastMonth);

        setGpfAmount(data.gpfTotal);
        setGpfLastweek(data.gpfLastWeek);
        setGpfLastMonth(data.gpfLastMonth);
      });
    }, []);


  return (
    <div className="superAdmin" style={{display:"flex",  flexWrap:"wrap", justifyContent:"center",alignContent:"center" , gap:"2rem"}}>
      <div className="featured">
        <div className="top">
          <h1 className="title">Total Gra Revenue</h1>
          <MoreVertIcon fontSize="small" />
        </div>
        <div className="bottom">
          <div className="featuredChart">
            <CircularProgressbar value={100} text={"100%"} strokeWidth={5} />
          </div>
          <p className="title">Total amount made </p>
          <p className="amount">D{graAmount}</p>
          <p className="desc">
            Previous transactions processing. Last payments may not be included.
          </p>
          <div className="summary">
            <div className="item">
              <div className="itemTitle">Target</div>
              <div className="itemResult negative">
                <KeyboardArrowDownIcon fontSize="small" />
                <div className="resultAmount">$12.4k</div>
              </div>
            </div>
            <div className="item">
              <div className="itemTitle">Last Week</div>
              <div className="itemResult positive">
                <KeyboardArrowUpOutlinedIcon fontSize="small" />
                <div className="resultAmount">D{graLastweek}</div>
              </div>
            </div>
            <div className="item">
              <div className="itemTitle">Last Month</div>
              <div className="itemResult positive">
                <KeyboardArrowUpOutlinedIcon fontSize="small" />
                <div className="resultAmount">D{graLastMonth}</div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="featured">
        <div className="top">
          <h1 className="title">Total GPF Revenue</h1>
          <MoreVertIcon fontSize="small" />
        </div>
        <div className="bottom">
          <div className="featuredChart">
            <CircularProgressbar value={100} text={"100%"} strokeWidth={5} />
          </div>
          <p className="title">Total amount made </p>
          <p className="amount">D{gpfAmount}</p>
          <p className="desc">
            Previous transactions processing. Last payments may not be included.
          </p>
          <div className="summary">
            <div className="item">
              <div className="itemTitle">Target</div>
              <div className="itemResult negative">
                <KeyboardArrowDownIcon fontSize="small" />
                <div className="resultAmount">$12.4k</div>
              </div>
            </div>
            <div className="item">
              <div className="itemTitle">Last Week</div>
              <div className="itemResult positive">
                <KeyboardArrowUpOutlinedIcon fontSize="small" />
                <div className="resultAmount">D{gpfLastweek}</div>
              </div>
            </div>
            <div className="item">
              <div className="itemTitle">Last Month</div>
              <div className="itemResult positive">
                <KeyboardArrowUpOutlinedIcon fontSize="small" />
                <div className="resultAmount">D{gpfLastMonth}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuperAdminData;
