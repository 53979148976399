import "./sidebar.scss";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PendingIcon from "@mui/icons-material/Pending";
import TableViewIcon from "@mui/icons-material/TableView";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import StoreIcon from "@mui/icons-material/Store";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CloseIcon from '@mui/icons-material/Close';

import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";

import { Link } from "react-router-dom";
import axiosClient from "../../axios-client";
import { useStateContext } from "../../context/ContextProvider";
import { DarkModeContext } from "../../context/darkModeContext";
import Navbar from "../navbar/Navbar";
import { useMediaQuery } from "@mui/material";

const drawerWidth = 30;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}%`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}%)`,
    marginLeft: `${drawerWidth}%`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function Newside() {
  const { user, token, setUser, setToken, notification } = useStateContext();

  const onLogout = (ev) => {
    ev.preventDefault();

    axiosClient.post("/logout").then(() => {
      setUser({});
      setToken(null);
    });
  };

  React.useEffect(() => {
    function setuser() {
      axiosClient.get("/user").then(({ data }) => {
        setUser(data);
      });
    }

    setuser();
  }, []);

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const { dispatch } = React.useContext(DarkModeContext);

  const isMobile = useMediaQuery("(max-width:1000px)");

  // const sidebarWidth = isMobile ? '50%' : '25%';
  // const isMobile = useMediaQuery({ maxWidth: 600 });

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <div className="navbar">
            <div className="wrapper">
              <div className="search">
                <input type="text" placeholder="Search..." />
                <SearchOutlinedIcon />
              </div>
              <div className="items">
                <div className="item">
                  <LanguageOutlinedIcon className="icon" />
                  {user.post}
                </div>
                <div className="item">
                  <DarkModeOutlinedIcon
                    className="icon"
                    onClick={() => dispatch({ type: "TOGGLE" })}
                  />
                </div>
                <div className="item">
                  <FullscreenExitOutlinedIcon className="icon" />
                </div>
                <div className="item">
                  <NotificationsNoneOutlinedIcon className="icon" />
                  <div className="counter">1</div>
                </div>
                <div className="item">
                  <ChatBubbleOutlineOutlinedIcon className="icon" />
                  <div className="counter">2</div>
                </div>
                <div className="item">
                  <ListOutlinedIcon className="icon" />
                </div>
                <div className="item">
                  {/* <img
              // src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
              alt=""
              className="avatar"
            /> */}
                  <div className="avatar">{user.name}</div>
                </div>
              </div>
            </div>
          </div>{" "}
        </Toolbar>
      </AppBar>
      <Drawer
        className="drawer sidebar"
        sx={{
          // width: `${drawerWidth}%`,
          width: isMobile ? "30%" : "25%",
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            // width: `${drawerWidth}%`,
            width: isMobile ? "30%" : "25%",
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "ltr" ? (
              <CloseIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
          <div className="top">
            <Link to="/" style={{ textDecoration: "none" }}>
              <span className="logo">{user.branch}</span>
            </Link>
          </div>
        </DrawerHeader>
        <Divider />

        {/* <hr /> */}
        <div className="center">
          <ul>
            <p className="title">MAIN</p>
            <Link to="/" style={{ textDecoration: "none" }}>
              <li>
                <DashboardIcon className="icon" />
                <span>Dashboard</span>
              </li>
            </Link>

            <p className="title">Registration</p>

            <Link to="/registration" style={{ textDecoration: "none" }}>
              <li>
                <InsertChartIcon className="icon" />
                <span>Register Driver</span>
              </li>
            </Link>

            <Link to="/q" style={{ textDecoration: "none" }}>
              <li>
                <HourglassBottomIcon className="icon" />
                <span>Approve Registrations</span>
              </li>
            </Link>
            <Link to="/pendingq" style={{ textDecoration: "none" }}>
              <li>
                <PendingIcon className="icon" />
                <span>Pending Queue</span>
              </li>
            </Link>
            <p className="title">Table</p>

            <Link to="/allregs" style={{ textDecoration: "none" }}>
              <li>
                <TableViewIcon className="icon" />
                <span>Records</span>
              </li>
            </Link>
            <p className="title">SERVICE</p>
            {user.email === "superadmin@gmail.com" ? (
              <Link to="/super" style={{ textDecoration: "none" }}>
                <li>
                  <AdminPanelSettingsIcon className="icon" />
                  <span>Super Admin</span>
                </li>
              </Link>
            ) : null}

            <li>
              <SettingsApplicationsIcon className="icon" />
              <span>Settings</span>
            </li>
            <p className="title">USER</p>

            {user.user_type === "graAdmin" || user.user_type === "gpfAdmin" ? (
              <Link to="/users" style={{ textDecoration: "none" }}>
                <li>
                  <PersonOutlineIcon className="icon" />
                  <span>Users</span>
                </li>
              </Link>
            ) : null}
            <Link to="/profile" style={{ textDecoration: "none" }}>
              <li>
                <AccountCircleOutlinedIcon className="icon" />
                <span>Profile</span>
              </li>
            </Link>
            <a
              onClick={onLogout}
              style={{ textDecoration: "none", color: "#888" }}
              href="#"
            >
              <li>
                <ExitToAppIcon className="icon" />
                <span> Logout</span>
              </li>
            </a>

            <div className="bottom">
              <div
                className="colorOption"
                onClick={() => dispatch({ type: "LIGHT" })}
              ></div>
              <div
                className="colorOption"
                onClick={() => dispatch({ type: "DARK" })}
              ></div>
            </div>
          </ul>
        </div>
        <Divider />
      </Drawer>

      {/* <Main open={open}>
        <DrawerHeader />
        <Typography paragraph>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Rhoncus dolor purus non
          enim praesent elementum facilisis leo vel. Risus at ultrices mi tempus
          imperdiet. Semper risus in hendrerit gravida rutrum quisque non tellus.
          Convallis convallis tellus id interdum velit laoreet id donec ultrices.
          Odio morbi quis commodo odio aenean sed adipiscing. Amet nisl suscipit
          adipiscing bibendum est ultricies integer quis. Cursus euismod quis viverra
          nibh cras. Metus vulputate eu scelerisque felis imperdiet proin fermentum
          leo. Mauris commodo quis imperdiet massa tincidunt. Cras tincidunt lobortis
          feugiat vivamus at augue. At augue eget arcu dictum varius duis at
          consectetur lorem. Velit sed ullamcorper morbi tincidunt. Lorem donec massa
          sapien faucibus et molestie ac.
        </Typography>
        <Typography paragraph>
          Consequat mauris nunc congue nisi vitae suscipit. Fringilla est ullamcorper
          eget nulla facilisi etiam dignissim diam. Pulvinar elementum integer enim
          neque volutpat ac tincidunt. Ornare suspendisse sed nisi lacus sed viverra
          tellus. Purus sit amet volutpat consequat mauris. Elementum eu facilisis
          sed odio morbi. Euismod lacinia at quis risus sed vulputate odio. Morbi
          tincidunt ornare massa eget egestas purus viverra accumsan in. In hendrerit
          gravida rutrum quisque non tellus orci ac. Pellentesque nec nam aliquam sem
          et tortor. Habitant morbi tristique senectus et. Adipiscing elit duis
          tristique sollicitudin nibh sit. Ornare aenean euismod elementum nisi quis
          eleifend. Commodo viverra maecenas accumsan lacus vel facilisis. Nulla
          posuere sollicitudin aliquam ultrices sagittis orci a.
        </Typography>
      </Main> */}
    </Box>
  );
}
