import React, { useEffect, useState } from "react";
import Paper from '@material-ui/core/Paper';
import {
Chart,
PieSeries,
Title,
Legend
} from '@devexpress/dx-react-chart-material-ui';
import {Animation} from '@devexpress/dx-react-chart';
import axiosClient from "../../axios-client";

const Piechart = () => {

const [pieData,setPieData] = useState([]);

useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axiosClient.get("/borders");
          setPieData(response.data);
        } catch (error) {
          console.error(error);
        }
      };
      fetchData();
}, [])



// Sample data
// const data = [
// { argument:'Monday', value:1 },
// { argument:'Tuesday', value:4 },
// { argument:'Wednesday', value:1 },
// { argument:'Thursday', value:2 },
// { argument:'Friday', value:2 },
// ];

// console.log(data);
return (
  
	<Paper className="piechart" style={{width:'100%'}}>
	<Chart
	data={pieData}
	>
	<PieSeries valueField="border_count" argumentField="border" />
    <Legend />
	<Title text="Border Traffic"/>
    <Animation/>
	</Chart>
</Paper>
);
}

export default Piechart;
