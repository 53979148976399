import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import axiosClient from "../../axios-client";
import { useNavigate } from "react-router-dom";
import { TextField } from "@mui/material";

export default function UserDialog(prop) {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const [passwords, setPasswords] = React.useState({
    password: "",
    password_confirmation: "",
  });

  const handlePasswordChange = (event) => {
    setPasswords({ 
        ...passwords, [event.target.name]: event.target.value
     });
  };

  const setPassword = (id) => {
    axiosClient
      .patch(`/password/${id}`,passwords)
      .then((response) => {
        // show success message
        console.log(response);
        handleClose();
        navigate("/");
      })
      .catch((error) => {
        // show error message
        console.log(error);
      });
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Set Password
      </Button>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">{"Set User Password"}</DialogTitle>
        <DialogContent>
          <DialogContentText>Password</DialogContentText>
          <TextField
            autoFocus
            name="password"
            margin="dense"
            label="Password"
            type="password"
            value={passwords.currentPassword}
            onChange={handlePasswordChange}
            fullWidth
            required
          />
          <TextField
          name="password_confirmation"
            margin="dense"
            label="Confirm Password"
            type="password"
            value={passwords.password_confirmation}
            onChange={handlePasswordChange}
            fullWidth
            required
          />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={() => setPassword(prop.id)} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
